@mixin link-style {
  text-transform: uppercase;
  text-decoration: none;  
}

$header-size: 120px;
$border-size: 1px;
$submenu-width: 300px;

header {
  flex: 1 0 auto;
  z-index: 10;
  background-color: black;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  border-bottom: #{$border-size} solid black;
  a {
    border-bottom: none;
  }
}

.mobile-menu {
  overflow: hidden;
  @include gt-desktop {
    overflow: visible;
  }
}

a.logo__link {
  transition: transform 0.3s;  
}

a.logo__link:focus {
  transform: scale(1.1);
}

a.logo__link:hover {
  transform: scale(1.1);
}

.header__menu {
}

.header__logo {
  flex: 1.0;
  @include gt-desktop {
    flex: 0.2;
  }
  img {
    height: calc(#{$header-size} / 2);
    vertical-align: bottom;
    object-fit: contain;
    @include gt-phone {
      height: $header-size;
    }
  }
}
.logo {
  flex-basis: auto; 
}

.navigation-menu {
  &__link {
    @include link-style;
    color: white;
  }

  &__title {
    text-align: center;
    line-height: 40px;
    width: 100vw;
    height: 40px;
    color: white;
    background-color: black;
    text-transform: uppercase;
    @include gt-desktop {
      width: auto;
    }
  }

  &__submenu {
    color: white;
    background-color: black;
    border: 1px white solid;
    transform: none;
    position: static;
    width: 100vw;
    @include gt-desktop {
      position: absolute;
      transform: translate(calc(-50% + 60px), 0);
      width: $submenu-width;
    }
    
    &__link {
      background-color: black;
      height: 40px;
      & > a {
        @include link-style;
        color: white;
      }
    }

    ul {
      line-height: 40px;
      list-style: none;
      margin: 0;
      padding: 0;
    }
    li {
      text-transform: uppercase;
      text-align: center;
      border-bottom: 1px solid white;
    }
  }
}

.navigation__item {
  flex-grow: 1.0;
  white-space: nowrap;
  transition: transform 0.3s;
  &:hover {
    cursor: pointer;
    transform: scale(1.2);
  }
  &:focus-within {
    transform: scale(1.2);
  }
  a {
    padding: 7px;
  }
}

.navigation-container {
  display: none;
  position: absolute;
  left: 0;
  border-bottom: #{$border-size} solid black;
  top: calc(#{$header-size} / 2 + #{$border-size});
  @include gt-phone {
    top: $header-size + $border-size;
  }
  @include gt-desktop {
    display: flex;
    justify-content: flex-start;
    gap: 30px; 
    position: static;
    border-bottom: none;
    }
  @include gt-lg-desktop {
    display: flex;
    justify-content: flex-start;
    gap: 50px; 
    position: static;
    border-bottom: none;
  }
  
  > ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
    @include gt-desktop {
      display: flex;
      justify-content: flex-start;
      gap: 30px; 
      position: static;
      border-bottom: none;
    }
    @include gt-lg-desktop {
      display: flex;
      justify-content: flex-start;
      gap: 50px; 
      position: static;
      border-bottom: none;
    }
  }
}

nav.navigation-container[open] {
  max-height: 70vh;
  overflow-x: hidden;
  overflow-y: auto;
  display: block;
  @include gt-desktop {
    overflow: visible;
    width: auto;
    display: flex;
  }
}

.bar1, .bar2, .bar3 {
  width: 35px;
  height: 5px;
  background-color: #FFF;
  margin: 6px 0;
  transition: 0.4s;
}


.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-8px, 8px);
  transform: rotate(-45deg) translate(-8px, 8px);
}

.change .bar2 {opacity: 0;}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}

#mobileMenuToggler {
  display: inline-block;
  cursor: pointer;
  border: 0;
  background-color: #000;
  @include gt-desktop {
    display: none;
  }
}
