@import 'colors';
// base styles (e.g. most common tags without classes) go here

// root element

html {
  height: 100%;
  margin: 0;
}

body {
  height: 100%;
  display: grid;
  grid-gap: 0;
  grid-template-columns: 1fr;
  grid-template-areas: "header" "main" "footer";
  grid-template-rows: 1fr;
  margin: 0;
  padding: 0;
  padding-top: 120px;
  @include lt-phone {
    padding-top: 40px;
  }
  
  color: black;
}

// Headings
h1 {
  font-family: 'Red Hat Display';
  font-weight: 800;
  color: $kummalintu-blue;
}
h2 {
  font-family: 'Red Hat Display';
  font-weight: 400;
  color: $kummalintu-blue;
}
h3 {
  font-family: 'Red Hat Display';
}
h4 {
  font-family: 'Red Hat Display';
}
h5 {
  font-family: 'Red Hat Display';
}
h6 {
  font-family: 'Red Hat Display';
}

p,a,ul,ol {
  font-family: 'Red Hat Display';
  @include gt-phone {
    font-size: 1.1rem;
  }
  @include gt-tablet {
    font-size: 1.1rem;
  }
  @include gt-desktop {
    font-size: 1.1rem;
  }
  @include gt-lg-desktop {
    font-size: 1.1rem;
  }  
}

a {
  color: black;
  text-decoration: none;
  border-bottom: 1px solid $kummalintu-orange;
}

a:hover,
a:focus {
  color: $kummalintu-orange-dark;
}

ul {}
ol {}

/*
* {
  border: 1px solid red;  
}
*/
