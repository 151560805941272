.fade-in {
  animation-name: fadeInOpacity;
  animation-timing-function: ease-in;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOutOpacity {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/* red-hat-display-300 - latin */
@font-face {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/red-hat-display-v14-latin-300.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/red-hat-display-v14-latin-300.eot?#iefix") format("embedded-opentype"), url("../fonts/red-hat-display-v14-latin-300.woff2") format("woff2"), url("../fonts/red-hat-display-v14-latin-300.woff") format("woff"), url("../fonts/red-hat-display-v14-latin-300.ttf") format("truetype"), url("../fonts/red-hat-display-v14-latin-300.svg#RedHatDisplay") format("svg");
  /* Legacy iOS */
}
/* red-hat-display-regular - latin */
@font-face {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/red-hat-display-v14-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/red-hat-display-v14-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/red-hat-display-v14-latin-regular.woff2") format("woff2"), url("../fonts/red-hat-display-v14-latin-regular.woff") format("woff"), url("../fonts/red-hat-display-v14-latin-regular.ttf") format("truetype"), url("../fonts/red-hat-display-v14-latin-regular.svg#RedHatDisplay") format("svg");
  /* Legacy iOS */
}
/* red-hat-display-800 - latin */
@font-face {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/red-hat-display-v14-latin-800.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/red-hat-display-v14-latin-800.eot?#iefix") format("embedded-opentype"), url("../fonts/red-hat-display-v14-latin-800.woff2") format("woff2"), url("../fonts/red-hat-display-v14-latin-800.woff") format("woff"), url("../fonts/red-hat-display-v14-latin-800.ttf") format("truetype"), url("../fonts/red-hat-display-v14-latin-800.svg#RedHatDisplay") format("svg");
  /* Legacy iOS */
}
/* red-hat-display-300italic - latin */
@font-face {
  font-family: "Red Hat Display";
  font-style: italic;
  font-weight: 300;
  src: url("../fonts/red-hat-display-v14-latin-300italic.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/red-hat-display-v14-latin-300italic.eot?#iefix") format("embedded-opentype"), url("../fonts/red-hat-display-v14-latin-300italic.woff2") format("woff2"), url("../fonts/red-hat-display-v14-latin-300italic.woff") format("woff"), url("../fonts/red-hat-display-v14-latin-300italic.ttf") format("truetype"), url("../fonts/red-hat-display-v14-latin-300italic.svg#RedHatDisplay") format("svg");
  /* Legacy iOS */
}
/* red-hat-display-italic - latin */
@font-face {
  font-family: "Red Hat Display";
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/red-hat-display-v14-latin-italic.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/red-hat-display-v14-latin-italic.eot?#iefix") format("embedded-opentype"), url("../fonts/red-hat-display-v14-latin-italic.woff2") format("woff2"), url("../fonts/red-hat-display-v14-latin-italic.woff") format("woff"), url("../fonts/red-hat-display-v14-latin-italic.ttf") format("truetype"), url("../fonts/red-hat-display-v14-latin-italic.svg#RedHatDisplay") format("svg");
  /* Legacy iOS */
}
/* red-hat-display-800italic - latin */
@font-face {
  font-family: "Red Hat Display";
  font-style: italic;
  font-weight: 800;
  src: url("../fonts/red-hat-display-v14-latin-800italic.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/red-hat-display-v14-latin-800italic.eot?#iefix") format("embedded-opentype"), url("../fonts/red-hat-display-v14-latin-800italic.woff2") format("woff2"), url("../fonts/red-hat-display-v14-latin-800italic.woff") format("woff"), url("../fonts/red-hat-display-v14-latin-800italic.ttf") format("truetype"), url("../fonts/red-hat-display-v14-latin-800italic.svg#RedHatDisplay") format("svg");
  /* Legacy iOS */
}
.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

html {
  height: 100%;
  margin: 0;
}

body {
  height: 100%;
  display: grid;
  grid-gap: 0;
  grid-template-columns: 1fr;
  grid-template-areas: "header" "main" "footer";
  grid-template-rows: 1fr;
  margin: 0;
  padding: 0;
  padding-top: 120px;
  color: black;
}
@media (max-width: 479px) {
  body {
    padding-top: 40px;
  }
}

h1 {
  font-family: "Red Hat Display";
  font-weight: 800;
  color: #386f76;
}

h2 {
  font-family: "Red Hat Display";
  font-weight: 400;
  color: #386f76;
}

h3 {
  font-family: "Red Hat Display";
}

h4 {
  font-family: "Red Hat Display";
}

h5 {
  font-family: "Red Hat Display";
}

h6 {
  font-family: "Red Hat Display";
}

p, a, ul, ol {
  font-family: "Red Hat Display";
}
@media (min-width: 480px) {
  p, a, ul, ol {
    font-size: 1.1rem;
  }
}
@media (min-width: 768px) {
  p, a, ul, ol {
    font-size: 1.1rem;
  }
}
@media (min-width: 1024px) {
  p, a, ul, ol {
    font-size: 1.1rem;
  }
}
@media (min-width: 1440px) {
  p, a, ul, ol {
    font-size: 1.1rem;
  }
}

a {
  color: black;
  text-decoration: none;
  border-bottom: 1px solid #ef772a;
}

a:hover,
a:focus {
  color: #be520e;
}

/*
* {
  border: 1px solid red;  
}
*/
.x-padding {
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 768px) {
  .x-padding {
    min-width: calc(1024px - (2 * 100px));
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (min-width: 1024px) {
  .x-padding {
    padding-left: 100px;
    padding-right: 100px;
  }
}
@media (min-width: 1440px) {
  .x-padding {
    padding-left: 200px;
    padding-right: 200px;
  }
}

.y-padding {
  padding-top: 30px;
  padding-bottom: 30px;
}
.y-padding__bottom {
  padding-bottom: 30px;
}

.container {
  width: auto;
}

.flex-container {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
}

.space-between {
  justify-content: space-between;
}

.x-padding {
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 768px) {
  .x-padding {
    min-width: calc(768px - (2 * 30px));
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (min-width: 1024px) {
  .x-padding {
    padding-left: 100px;
    padding-right: 100px;
  }
}
@media (min-width: 1440px) {
  .x-padding {
    padding-left: 200px;
    padding-right: 200px;
  }
}

.y-padding {
  padding-top: 30px;
  padding-bottom: 30px;
}

.container {
  width: auto;
}

.flex-container {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
}

.space-between {
  justify-content: space-between;
}

.button {
  border-radius: 20px 0 20px 0;
  box-shadow: 15px 15px 15px 0px rgba(0, 0, 0, 0.2);
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
  max-width: 100%;
  line-height: 38px;
  height: 38px;
  text-align: center;
  display: inline-block;
  border-bottom: 0px;
  background-color: #386f76;
  color: white;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
  text-transform: uppercase;
  transition: transform 0.3s;
}
.button:hover {
  cursor: pointer;
  transform: scale(1.2);
  color: white;
}
.button:focus-within {
  transform: scale(1.2);
}
.button__symbol {
  vertical-align: center;
  font-size: 200%;
  margin: 0;
  padding: 0;
  line-height: 100%;
}
@media (min-width: 768px) {
  .button {
    width: 200px;
    padding-left: 16px;
    padding-right: 16px;
  }
}
@media (max-width: 767px) {
  .button {
    max-width: calc(100% - 32px);
    padding-left: 16px;
    padding-right: 16px;
  }
}

.main-container {
  flex: 1 0 auto;
}

header {
  flex: 1 0 auto;
  z-index: 10;
  background-color: black;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  border-bottom: 1px solid black;
}
header a {
  border-bottom: none;
}

.mobile-menu {
  overflow: hidden;
}
@media (min-width: 1024px) {
  .mobile-menu {
    overflow: visible;
  }
}

a.logo__link {
  transition: transform 0.3s;
}

a.logo__link:focus {
  transform: scale(1.1);
}

a.logo__link:hover {
  transform: scale(1.1);
}

.header__logo {
  flex: 1;
}
@media (min-width: 1024px) {
  .header__logo {
    flex: 0.2;
  }
}
.header__logo img {
  height: calc(120px / 2);
  vertical-align: bottom;
  object-fit: contain;
}
@media (min-width: 480px) {
  .header__logo img {
    height: 120px;
  }
}

.logo {
  flex-basis: auto;
}

.navigation-menu__link {
  text-transform: uppercase;
  text-decoration: none;
  color: white;
}
.navigation-menu__title {
  text-align: center;
  line-height: 40px;
  width: 100vw;
  height: 40px;
  color: white;
  background-color: black;
  text-transform: uppercase;
}
@media (min-width: 1024px) {
  .navigation-menu__title {
    width: auto;
  }
}
.navigation-menu__submenu {
  color: white;
  background-color: black;
  border: 1px white solid;
  transform: none;
  position: static;
  width: 100vw;
}
@media (min-width: 1024px) {
  .navigation-menu__submenu {
    position: absolute;
    transform: translate(calc(-50% + 60px), 0);
    width: 300px;
  }
}
.navigation-menu__submenu__link {
  background-color: black;
  height: 40px;
}
.navigation-menu__submenu__link > a {
  text-transform: uppercase;
  text-decoration: none;
  color: white;
}
.navigation-menu__submenu ul {
  line-height: 40px;
  list-style: none;
  margin: 0;
  padding: 0;
}
.navigation-menu__submenu li {
  text-transform: uppercase;
  text-align: center;
  border-bottom: 1px solid white;
}

.navigation__item {
  flex-grow: 1;
  white-space: nowrap;
  transition: transform 0.3s;
}
.navigation__item:hover {
  cursor: pointer;
  transform: scale(1.2);
}
.navigation__item:focus-within {
  transform: scale(1.2);
}
.navigation__item a {
  padding: 7px;
}

.navigation-container {
  display: none;
  position: absolute;
  left: 0;
  border-bottom: 1px solid black;
  top: calc(120px / 2 + 1px);
}
@media (min-width: 480px) {
  .navigation-container {
    top: 121px;
  }
}
@media (min-width: 1024px) {
  .navigation-container {
    display: flex;
    justify-content: flex-start;
    gap: 30px;
    position: static;
    border-bottom: none;
  }
}
@media (min-width: 1440px) {
  .navigation-container {
    display: flex;
    justify-content: flex-start;
    gap: 50px;
    position: static;
    border-bottom: none;
  }
}
.navigation-container > ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: block;
}
@media (min-width: 1024px) {
  .navigation-container > ul {
    display: flex;
    justify-content: flex-start;
    gap: 30px;
    position: static;
    border-bottom: none;
  }
}
@media (min-width: 1440px) {
  .navigation-container > ul {
    display: flex;
    justify-content: flex-start;
    gap: 50px;
    position: static;
    border-bottom: none;
  }
}

nav.navigation-container[open] {
  max-height: 70vh;
  overflow-x: hidden;
  overflow-y: auto;
  display: block;
}
@media (min-width: 1024px) {
  nav.navigation-container[open] {
    overflow: visible;
    width: auto;
    display: flex;
  }
}

.bar1, .bar2, .bar3 {
  width: 35px;
  height: 5px;
  background-color: #FFF;
  margin: 6px 0;
  transition: 0.4s;
}

.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-8px, 8px);
  transform: rotate(-45deg) translate(-8px, 8px);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}

#mobileMenuToggler {
  display: inline-block;
  cursor: pointer;
  border: 0;
  background-color: #000;
}
@media (min-width: 1024px) {
  #mobileMenuToggler {
    display: none;
  }
}

footer {
  flex-shrink: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: black;
  color: white;
}
footer a {
  color: white;
}

.some svg {
  fill: white;
  width: 32px;
  height: 32px;
  transition: transform 0.3s;
}

.some a {
  border-bottom: none;
  padding: 15px;
  height: 32px;
  width: 32px;
  fill: white;
}
.some a:hover svg {
  transform: scale(1.2);
}
.some a:focus-within svg {
  transform: scale(1.2);
}

.julkaisu__container {
  margin-left: auto;
  margin-right: auto;
  width: 95%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}
@media (min-width: 480px) {
  .julkaisu__container {
    width: 65%;
  }
}
@media (min-width: 1024px) {
  .julkaisu__container {
    width: 100%;
    flex-direction: row;
  }
}

.publication__subtitle {
  font-size: 28px;
  font-weight: 400;
}
.publication__basic-info {
  width: 300px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
}
.publication__person {
  font-size: 1.1rem;
  font-family: "Red Hat Display";
  padding-top: 5px;
  padding-bottom: 5px;
}
.publication__persons-container {
  display: flex;
  gap: 10px;
  justify-content: space-between;
}
.publication__person-heading {
  margin-bottom: 10px;
  font-size: 20px;
  color: #be520e;
}
.publication__info-row span:first-child {
  margin-right: 1ch;
  font-weight: bold;
}
.publication__default-image {
  opacity: 0.1;
}
.publication__author {
  font-family: "Red Hat Display";
  font-weight: bold;
}
.publication__author a {
  display: inline-block;
  transition: transform 0.4s;
}
.publication__author a:hover {
  cursor: pointer;
  transform: scale(1.2);
}
.publication__author a:focus {
  transform: scale(1.2);
}
.publication__isbn {
  font-size: 12px;
}
.publication__gap {
  margin-top: 12px;
  margin-bottom: 12px;
}
.publication__cover {
  background-color: #d1f1f5;
  width: 100%;
  max-width: 400px;
  box-shadow: 15px 15px 15px 0px rgba(0, 0, 0, 0.2);
}
.publication__cover img {
  width: 100%;
  max-width: 400px;
}
.publication__container {
  text-align: justify;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
}
@media (max-width: 1023px) {
  .publication__container {
    flex-direction: column-reverse;
  }
}
.publication__info {
  width: 100%;
}
@media (min-width: 768px) {
  .publication__info {
    width: 50%;
  }
}
.publication__author-blurp {
  background-color: #eeeeee;
  margin-top: 10px;
  padding-bottom: 10px;
}
.publication__author-subcontainer {
  display: flex;
  gap: 15px;
}
@media (max-width: 1023px) {
  .publication__author-subcontainer {
    flex-direction: column;
  }
}
.publication__author-blurp-image {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}
.publication__author-blurp-image img {
  width: 250px;
  height: auto;
  box-shadow: 15px 15px 15px 0px rgba(0, 0, 0, 0.2);
}

a.publication__sales-link {
  display: inline-block;
  transition: transform 0.4s;
}
a.publication__sales-link:hover {
  cursor: pointer;
  transform: scale(1.2);
}
a.publication__sales-link:focus {
  transform: scale(1.2);
}

.publication-list-item {
  max-width: 220px;
  height: 475px;
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 1;
}
.publication-list-item:hover .publication-list-item__bg {
  transform: translate(0, -5px);
  background-color: #f2d2be;
}
.publication-list-item:hover .publication-list-item__info {
  background-color: #d1b0ac;
}
.publication-list-item a:focus .publication-list-item__bg {
  transform: translate(0, -5px);
  background-color: #f2d2be;
}
.publication-list-item a:focus .publication-list-item__info {
  background-color: #d1b0ac;
}
.publication-list-item__bg {
  transition: transform 0.3s;
  min-height: 100%;
  background-color: #d1f1f5;
  color: black;
  box-shadow: 15px 15px 15px 0px rgba(0, 0, 0, 0.0666666667);
}
.publication-list-item_small {
  max-width: 150px;
  height: 325px;
}
.publication-list-item_small .publication-list-item__title {
  font-size: 90%;
}
.publication-list-item_small .publication-list-item__cover {
  max-width: 150px;
  max-height: 225px;
}
.publication-list-item_small .publication-list-item__cover > img {
  max-width: 100%;
}
.publication-list-item_small .publication-list-item__info {
  height: 100px;
}
.publication-list-item_author .publication-list-item__cover img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.publication-list-item__default-image {
  opacity: 0.1;
}
.publication-list-item__info {
  background-color: #b0e1f5;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  height: 125px;
}
.publication-list-item__cover {
  overflow: hidden;
  width: 220px;
  height: 350px;
  display: flex;
  flex-direction: column-reverse;
}
.publication-list-item__cover > img {
  transition: filter 0.3s;
  filter: contrast(100%);
  object-fit: contain;
  width: 220px;
}
.publication-list-item__cover > img:hover {
  filter: contrast(130%);
}
.publication-list-item__author {
  text-align: justify;
  padding-bottom: 20px;
  margin-bottom: 30px;
  color: #333333;
  font-size: 14px;
  max-height: 35px;
  text-overflow: ellipsis;
}
.publication-list-item__author ::after {
  content: "...";
  position: absolute;
  bottom: 0;
  right: 0;
}
.publication-list-item__title {
  hyphens: auto;
  font-size: 18px;
  letter-spacing: 0px;
  text-align: normal;
  margin: 0;
  width: 100%;
  color: black;
  font-weight: 600;
}
.publication-list-item__subtitle {
  hyphens: auto;
  font-size: 14px;
  text-align: justify;
  margin: 0;
  width: 100%;
  padding-bottom: 10px;
  color: black;
}
.publication-list-item__upcoming {
  width: fit-content;
  text-transform: uppercase;
  z-index: 1;
  margin: 0;
  position: absolute;
  background-color: orange;
  padding: 8px;
  background: linear-gradient(90deg, rgb(255, 217, 27) 0%, rgb(255, 165, 0) 100%);
  font-size: small;
}
.publication-list-item__upcoming-container {
  position: relative;
  top: -15px;
}

.article-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.article-card {
  margin-left: 0;
  margin-right: 0;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 500px;
  min-width: 300px;
  max-width: 300px;
  transition: transform 0.3s;
  box-shadow: 15px 15px 15px 0px rgba(0, 0, 0, 0.0666666667);
  background-color: #d1f1f5;
}
.article-card__date {
  color: black;
}
.article-card a {
  border: none;
}
.article-card h2 {
  color: black;
  font-weight: 600;
  font-size: 18px;
  margin: 0;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0;
  padding-bottom: 0;
}
.article-card .article-card__info {
  padding-left: 10px;
  padding-right: 10px;
  width: calc(100% - 20px);
  height: 200px;
  background-color: #b0e1f5;
}
.article-card:hover {
  transform: translate(0, -5px);
  background-color: #e2d2be;
}
.article-card:hover .article-card__info {
  background-color: #d1b0ac;
  color: black;
}

.article-card__image {
  width: 300px;
  height: 300px;
}
.article-card__image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: 50% 100%;
}

.article-image {
  width: 800px;
  height: auto;
  max-width: 100%;
}

.markdown-content .float-right {
  float: right;
}
.markdown-content .float-left {
  float: left;
}
.markdown-content figcaption {
  margin-top: 20px;
}
.markdown-content img {
  box-shadow: 15px 15px 15px 0px rgba(0, 0, 0, 0.2);
}
.markdown-content .one-fourth {
  max-width: 25%;
}
.markdown-content .one-fourth img {
  width: 100%;
}

.author__container {
  display: flex;
  gap: 10px;
}
@media (max-width: 1023px) {
  .author__container {
    flex-direction: column;
  }
}
.author__image-container {
  display: flex;
  flex-direction: column;
}
.author__image-credit {
  font-size: 1rem;
}
.author__image {
  min-width: 300px;
  min-height: 400px;
  max-width: 400px;
  max-height: 550px;
}
.author__image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  box-shadow: 15px 15px 15px 0px rgba(0, 0, 0, 0.0666666667);
}
.author__social-media-link {
  padding-top: 10px;
  padding-bottom: 10px;
}
.author__social-media-link a {
  font-size: 20px;
}
.author__books-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ef772a;
}
.author__books-container:last-of-type {
  border-bottom: none;
}

.hero {
  padding: 20px;
  text-align: center;
  font-size: 2em;
  font-variant: small-caps;
}
.hero .secondary {
  font-size: 75%;
}

.lift-container {
  display: flex;
  gap: 10px;
  padding: 10px;
}

.lift {
  background-color: #b0e1f5;
  padding: 10px;
}

.lift h1 {
  color: black;
}

.hero {
  margin: 0;
  padding: 0;
}
.hero__pad-top {
  padding-top: 30px;
}
.hero img {
  width: 100%;
  height: auto;
}
.hero a {
  border: none;
}

.indiespec-flag-logo {
  display: flex;
}
.indiespec-flag-logo img {
  object-fit: cover;
  width: 33.3333333333%;
  height: auto;
}

.indiespec-publishers {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: space-between;
}
.indiespec-publishers__logo {
  display: flex;
  height: auto;
  width: 100%;
}
.indiespec-publishers__logo img {
  object-fit: contain;
}
.indiespec-publishers div {
  align-items: center;
}
.indiespec-publishers img {
  width: 20rem;
  object-fit: cover;
  box-shadow: none;
}
@media (max-width: 479px) {
  .indiespec-publishers img {
    width: 100%;
  }
}

.indiespec-publist {
  display: flex;
}
.indiespec-publist li {
  width: 33.3333333333%;
  margin-bottom: 3rem;
}
@media (max-width: 767px) {
  .indiespec-publist {
    flex-direction: column;
  }
  .indiespec-publist li {
    width: 100%;
  }
}

.indiespec-eu {
  width: 25%;
}
@media (max-width: 767px) {
  .indiespec-eu {
    width: 50%;
  }
}
@media (max-width: 479px) {
  .indiespec-eu {
    width: 100%;
  }
}