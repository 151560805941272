// Font declarations go here.

/* red-hat-display-300 - latin */
@font-face {
  font-family: 'Red Hat Display';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/red-hat-display-v14-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/red-hat-display-v14-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/red-hat-display-v14-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/red-hat-display-v14-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/red-hat-display-v14-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/red-hat-display-v14-latin-300.svg#RedHatDisplay') format('svg'); /* Legacy iOS */
}
/* red-hat-display-regular - latin */
@font-face {
  font-family: 'Red Hat Display';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/red-hat-display-v14-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/red-hat-display-v14-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/red-hat-display-v14-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/red-hat-display-v14-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/red-hat-display-v14-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/red-hat-display-v14-latin-regular.svg#RedHatDisplay') format('svg'); /* Legacy iOS */
}
/* red-hat-display-800 - latin */
@font-face {
  font-family: 'Red Hat Display';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/red-hat-display-v14-latin-800.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/red-hat-display-v14-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/red-hat-display-v14-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/red-hat-display-v14-latin-800.woff') format('woff'), /* Modern Browsers */
       url('../fonts/red-hat-display-v14-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/red-hat-display-v14-latin-800.svg#RedHatDisplay') format('svg'); /* Legacy iOS */
}
/* red-hat-display-300italic - latin */
@font-face {
  font-family: 'Red Hat Display';
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/red-hat-display-v14-latin-300italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/red-hat-display-v14-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/red-hat-display-v14-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/red-hat-display-v14-latin-300italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/red-hat-display-v14-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/red-hat-display-v14-latin-300italic.svg#RedHatDisplay') format('svg'); /* Legacy iOS */
}
/* red-hat-display-italic - latin */
@font-face {
  font-family: 'Red Hat Display';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/red-hat-display-v14-latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/red-hat-display-v14-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/red-hat-display-v14-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/red-hat-display-v14-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/red-hat-display-v14-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/red-hat-display-v14-latin-italic.svg#RedHatDisplay') format('svg'); /* Legacy iOS */
}
/* red-hat-display-800italic - latin */
@font-face {
  font-family: 'Red Hat Display';
  font-style: italic;
  font-weight: 800;
  src: url('../fonts/red-hat-display-v14-latin-800italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/red-hat-display-v14-latin-800italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/red-hat-display-v14-latin-800italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/red-hat-display-v14-latin-800italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/red-hat-display-v14-latin-800italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/red-hat-display-v14-latin-800italic.svg#RedHatDisplay') format('svg'); /* Legacy iOS */
}
