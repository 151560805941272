$list-item-height: 475px;
$list-item-height-small: 325px;
$list-cover-width: 220px;
$list-cover-width-small: 150px;
$list-cover-height: 350px;
$list-cover-height-small: 225px;
$list-gap: 20px;

.julkaisu__container {
  margin-left: auto;
  margin-right: auto;
  width: 95%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;

  @include gt-phone {
    width: 65%;
  }
  @include gt-desktop {
    width: 100%;
    flex-direction: row;
  } 
}

.julkaisu__block {
}

.publication {

  &__subtitle {
    font-size: 28px;
    font-weight: 400;
  }
  
  &__basic-info {
    width: 300px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
  }

  &__person {
    font-size: 1.1rem;
    font-family: 'Red Hat Display';
    padding-top: 5px;
    padding-bottom: 5px;
  }

  &__persons-container {
    display: flex;
    gap: 10px;
    justify-content: space-between;
  }

  &__person-heading {
    margin-bottom: 10px;
    font-size: 20px;
    color: $kummalintu-orange-dark;
  }

  &__info-row {
    span:first-child {
      margin-right: 1ch;
      font-weight: bold;
    }
  }
  
  &__default-image {
    opacity: 0.1;
  }

  &__author {
    font-family: 'Red Hat Display';
    font-weight: bold;

    a {
      @include grow-on-hover;
    }
  }
  &__isbn {
    font-size: 12px;
  }
  &__gap {
    margin-top: 12px;
    margin-bottom: 12px;
  }
  &__cover {
    background-color: #d1f1f5;
    width: 100%;
    max-width: 400px;
    box-shadow: 15px 15px 15px 0px #00000033;
    img {
      width: 100%;
      max-width: 400px;
    }
  }
  &__container {
    text-align: justify;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
    @include lt-desktop {
      flex-direction: column-reverse;
    }
  }
  &__info {
    width: 100%;
    @include gt-tablet {
      width: 50%;
    }
  }
  &__author-blurp {
    background-color: #eeeeee;
    margin-top: 10px;
    padding-bottom: 10px;
  }

  &__author-subcontainer {
    display: flex;
    gap: 15px;
    @include lt-desktop {
      flex-direction: column;
    }
  }

  &__author-blurp-image {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    img {
      width: 250px;
      height: auto;
      box-shadow: 15px 15px 15px 0px #00000033;
    }
  }
}

a.publication__sales-link {
  display: inline-block;
  transition: transform 0.4s;
  &:hover {
    cursor: pointer;
    transform: scale(1.2);
  }
  &:focus {
    transform: scale(1.2);
  }
}

.publication-list-item {
  max-width: $list-cover-width;
  height: $list-item-height;
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 1;

  &:hover {
    .publication-list-item__bg {
      transform: translate(0, -5px);
      background-color: #f2d2be;
    }
    .publication-list-item__info {
      background-color: #d1b0ac;
    }
  }
  & a:focus {
    .publication-list-item__bg {
      transform: translate(0, -5px);
      background-color: #f2d2be;
    }
    
    .publication-list-item__info {
      background-color: #d1b0ac;
    }
  }
  
  &__bg {
    transition: transform 0.3s;
    min-height: 100%;
    background-color: #d1f1f5;
    color: black;
    box-shadow: 15px 15px 15px 0px #00000011;
  }
  
  &_small {
    max-width: $list-cover-width-small;
    height: $list-item-height-small;
    
    .publication-list-item__title {
      font-size: 90%;
    }
    
    .publication-list-item__cover {
      max-width: $list-cover-width-small;
      max-height: $list-cover-height-small;
      > img {
        max-width: 100%;
      }
    }
    
    .publication-list-item__info {
      height: calc($list-item-height-small - $list-cover-height-small);
    }
  }

  &_author {
    .publication-list-item {
      &__cover {
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  
  &__default-image {
    opacity: 0.1;
  }

  &__info {
    background-color: $infobox-light-blue;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;    
    height: calc($list-item-height - $list-cover-height);
  }
  
  &__cover {
    overflow: hidden;
    width: $list-cover-width;
    height: $list-cover-height;
    display: flex;
    flex-direction: column-reverse;

    > img {
      transition: filter 0.3s;
      filter: contrast(100%);
      object-fit: contain;
      width: $list-cover-width;
    }
    > img:hover {
      filter: contrast(130%);
    }
  }

  &__author {
    text-align: justify;
    padding-bottom: 20px;
    margin-bottom: 30px;
    color: #333333;
    font-size: 14px;
    max-height: 35px;
    text-overflow: ellipsis;
    ::after {
      content: '...';
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
  &__title {
    hyphens: auto;
    font-size: 18px;
    letter-spacing: 0px;
    text-align: normal;
    margin: 0;
    width: 100%;
    color: black;
    font-weight: 600;
  }
  &__subtitle {
    hyphens: auto;
    font-size: 14px;
    text-align: justify;
    margin: 0;
    width: 100%;
    padding-bottom: 10px;
    color: black; 
  }

  &__upcoming {
    width: fit-content;
    text-transform: uppercase;
    z-index: 1;
    margin: 0;
    position: absolute;
    background-color: orange;
    padding: 8px;
    background: linear-gradient(90deg, rgba(255,217,27,1) 0%, rgba(255,165,0,1) 100%);
    font-size: small;
  }

  &__upcoming-container         {
    position: relative;
    top: -15px;
    // left: -8px;    
  }

}

