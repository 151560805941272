.fade-in {
	animation-name: fadeInOpacity;
	animation-timing-function: ease-in;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fadeOutOpacity {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@mixin grow-on-hover {
  display: inline-block;
  transition: transform 0.4s;
  &:hover {
    cursor: pointer;
    transform: scale(1.2);
  }
  &:focus {
    transform: scale(1.2);
  }
}
