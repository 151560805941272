footer {
  flex-shrink: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: black;
  color: white;

  a {
  color: white;
  }
}

.some svg {
  fill: white;
  width: 32px;
  height: 32px;
  transition: transform 0.3s;
}

.some a {
  border-bottom: none;
  padding: 15px;
  height: 32px;
  width: 32px;
  fill: white;
  &:hover {
    svg {
      transform: scale(1.2);
    }
  }
  &:focus-within {
    svg {
      transform: scale(1.2);
    }
  }
}
