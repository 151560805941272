.hero {
  padding: 20px;
  text-align: center;
  font-size: 2em;
  font-variant: small-caps;

  .secondary {
    font-size: 75%;
  }
}

.lift-container {
  display: flex;
  gap: 10px;
  padding: 10px;
}

.lift {
  background-color: $infobox-light-blue;
;
  padding: 10px;
}

.lift h1 {
  color: black;
}
