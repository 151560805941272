// This file should cover any site-wide classes, such as classes specifying regular margin breaks or padding

$phone-sides: 15px;
$tablet-sides: 30px;
$desktop-sides: 100px;
$lg-desktop-sides: 200px;

@mixin basic-container {
  width: auto;
}

// Standard left-right padding for page
.x-padding {
  padding-left: $phone-sides;
  padding-right: $phone-sides;
  @include gt-tablet {
    min-width: calc(#{$desktop-width} - (2 * #{$desktop-sides}));
    padding-left: $tablet-sides;
    padding-right: $tablet-sides;    
  }
  @include gt-desktop {
    padding-left: $desktop-sides;
    padding-right: $desktop-sides;    
  }
  @include gt-lg-desktop {
    padding-left: $lg-desktop-sides;
    padding-right: $lg-desktop-sides;    
  }
}

// Standard top-bottom padding for page
.y-padding {
  padding-top: 30px;
  padding-bottom: 30px;

  &__bottom {
    padding-bottom: 30px;
  }
}

// Basic container style
.container {
  @include basic-container;
}

// Container with horizontal flex and center justification
.flex-container {
  @include basic-container;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
}

// Change justification to space-between
.space-between {
  justify-content: space-between;
}
$phone-sides: 15px;
$tablet-sides: 30px;
$desktop-sides: 100px;
$lg-desktop-sides: 200px;

@mixin basic-container {
  width: auto;
}

// Standard left-right padding for page
.x-padding {
  padding-left: $phone-sides;
  padding-right: $phone-sides;
  @include gt-tablet {
    min-width: calc(#{$tablet-width} - (2 * #{$tablet-sides}));
    padding-left: $tablet-sides;
    padding-right: $tablet-sides;    
  }
  @include gt-desktop {
    padding-left: $desktop-sides;
    padding-right: $desktop-sides;    
  }
  @include gt-lg-desktop {
    padding-left: $lg-desktop-sides;
    padding-right: $lg-desktop-sides;    
  }
}

// Standard top-bottom padding for page
.y-padding {
  padding-top: 30px;
  padding-bottom: 30px;
}

// Basic container style
.container {
  @include basic-container;
}

// Container with horizontal flex and center justification
.flex-container {
  @include basic-container;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
}

// Change justification to space-between
.space-between {
  justify-content: space-between;
}

.button {
  border-radius: 20px 0 20px 0;
  box-shadow: 15px 15px 15px 0px #00000033;
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
  max-width: 100%;
  line-height: 38px;
  height: 38px;
  text-align: center;
  display: inline-block;
  border-bottom: 0px;
  background-color: $kummalintu-blue;
  color: white;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
  text-transform: uppercase;
  transition: transform 0.3s;
  &:hover {
    cursor: pointer;
    transform: scale(1.2);
    color: white;
  }
  &:focus-within {
    transform: scale(1.2);
  }
  &__symbol {
    vertical-align: center;
    font-size: 200%;
    margin: 0;
    padding: 0;
    line-height: 100%;
  }
  @include gt-tablet {
    width: 200px;
    padding-left: 16px;
    padding-right: 16px;
  }
  @include lt-tablet {
    max-width: calc(100% - 2 * 16px);
    padding-left: 16px;
    padding-right: 16px;
  }
}

// main wrapper next to body
.main-container {
  flex: 1 0 auto;
}

// main content wrapper
.page-container {
}
