.hero {
  &__pad-top {
    padding-top: 30px;
  }

  margin: 0;
  padding: 0;
  img {

    width: 100%;
    height: auto;
  }
  a {
    border: none;
  }
}

