.indiespec-flag-logo {
	display: flex;

	img {
		object-fit: cover;
		width: calc(100% / 3);
		height: auto;
	}
}

.indiespec-publishers {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	justify-content: space-between;

	&__logo {
		display: flex;
		height: auto;
		width: 100%;
		img {
			object-fit: contain;
		}
	}

	div {
		align-items: center;
	}

	img {
		width: 20rem;
		object-fit: cover;
		box-shadow: none;
		@include lt-phone {
			width: 100%;
		}
	}
}
.indiespec-publist {
	display: flex;
	li {
		width: calc(100% / 3);
		margin-bottom: 3rem;
	}
	@include lt-tablet {
		flex-direction: column;
		li {
			width: 100%;
		}
	}
}

.indiespec-eu {
	width: 25%;
	@include lt-tablet {
		width: 50%;
	}
	@include lt-phone {
		width: 100%;
	}
}