.article-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.article-card {
  margin-left: 0;
  margin-right: 0;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 500px;
  min-width: 300px;
  max-width: 300px;
  transition: transform 0.3s;
  box-shadow: 15px 15px 15px 0px #00000011;
  background-color: $infobox-lighter-blue;

  &__date {
    color: black;
  }

  a {
    border: none;
  }
  
  h2 {
    color: black;
    font-weight: 600;
    font-size: 18px;
    margin: 0;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .article-card__info {
    padding-left: 10px;
    padding-right: 10px;  
    width: calc(100% - 2 * 10px);
    height: 200px;
    background-color: $infobox-light-blue;
  }
  
 
  &:hover {
    transform: translate(0, -5px);
    background-color: #e2d2be;
    .article-card__info {
      background-color: #d1b0ac;
      color: black;
    }
  }
}

.article-card__image {
  width: 300px;
  height: 300px;

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: 50% 100%;
  }
}

.article-image {
  width: 800px;
  height: auto;
  max-width: 100%;
}

