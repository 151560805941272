.author {
  &__container {
    display: flex;
    gap: 10px;
    @include lt-desktop {
      flex-direction: column;
    }
  }

  &__image-container {
    display: flex;
    flex-direction: column;
  }

  &__image-credit {
    font-size: 1rem;
  }

  &__image {
    min-width: 300px;
    min-height: 400px;
    max-width: 400px;
    max-height: 550px;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      box-shadow: 15px 15px 15px 0px #00000011;
    }
  }

  &__social-media-link {
    padding-top: 10px;
    padding-bottom: 10px;
    a {
      font-size: 20px;
    }
  }

  &__books-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid $kummalintu-orange;
    @include lt-tablet {
      //flex-direction: column;
    }

    &:last-of-type {
      border-bottom: none;
    }
  }
}
