.markdown-content {

  .float-right {
    float: right;
  }

  .float-left {
    float: left;
  }
  
  figcaption {
    margin-top: 20px;
  }

  img {
    box-shadow: 15px 15px 15px 0px #00000033;
  }

  .one-fourth {
    max-width: 25%;
    img {
      width: 100%;
    }
  }
}
